import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ObservationResidency from '@/Models/ObservationResidency'
import Crm from '@/Models/Crm'
import http from '@/services/http'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormObservationResidency extends Vue {
  @Prop({ required: false, default: null })
  objMember!: Crm
  @Prop({ required: false, default: null })
  objRow!: ObservationResidency
  @Prop({ required: false, default: null })
  isAction!: string

  objObservationResidency: ObservationResidency = new ObservationResidency()
  loading = false

  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.objObservationResidency.residency_id = parseInt(
      String(menuModule.id_param),
    )
    console.log(this.objMember)
    if (this.objRow && this.isAction == 'edit') {
      this.objObservationResidency.date = this.objRow.date
      this.objObservationResidency.observation = this.objRow.observation
      console.log('hola', this.objRow.id)
    }
  }
  async confirmation() {
    const observation = this.observation(this.objObservationResidency)
    switch (this.isAction) {
      case 'add':
        await this.createObservationResidency(observation)
        break
      case 'edit':
        await this.updateObservationResidency(observation)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createObservationResidency(objObservationResidency: any) {
    try {
      console.log(objObservationResidency)
      await http.post(
        `/api/residency/arcr_residency_observations/`,
        objObservationResidency,
      )
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error) {
      console.error(error)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateObservationResidency(objObservationResidency: any) {
    console.log(this.objRow.id)
    try {
      await http.put(
        `api/residency/arcr_residency_observations/${this.objRow.id}/`,
        objObservationResidency,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      console.log(error)
    }
  }
  observation(objObservation: ObservationResidency) {
    const observation = {
      id: objObservation.id,
      residency_id: objObservation.residency_id,
      date: Helpers.dateFormat_db(objObservation.date),
      observation: objObservation.observation,
    }
    return observation
  }
  close() {
    this.objObservationResidency = new ObservationResidency()
    this.$emit('cleanForm')
  }
}
